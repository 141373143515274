import { navigate } from "gatsby-link";
import React, { useEffect } from "react";

const IndexPage: React.FC = () => {
  useEffect(() => {
    // Automatically redirect to default (en-us) homepage.
    navigate("/en-us/");
  }, []);

  return null;
};

export default IndexPage;
